import React from "react";
import styled from "styled-components";
import { Link } from 'gatsby'

const ProductGroup = styled.div`
background-image: linear-gradient(to bottom, #1D1D1D 0%,#1D1D1D 100%);
box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.25);
border-radius: 20px;
text-align: center;
align-content: center;
margin: 16px 16px;
padding:8px 16px;
display: grid;
grid-template-columns: 1fr;
transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
backface-visibility: hidden;
-webkit-transform: translateZ(0px);
width: 200px;

:hover {


}

hr {

background: linear-gradient(185.19deg, #22E1FF 0%, #1D8FE1 47.87%, #625EB1 100%);
border-radius: 6px;
width: 33px;
height: 3px;
margin: 8px auto;
outline: none;
border: none;

}

`

const Copy = styled.p`
font-size: 16px;
color: white;
margin: 0;
padding-bottom: 4px;


`

const ProductTitle = styled.h4`
font-size: 19px;
color: white;
margin: 0;

`


const ProductChip = props => (
    <ProductGroup>
    <Link to={props.url}>
        <ProductTitle>{props.title}</ProductTitle>
        <hr></hr>
        <Copy>{props.copy}</Copy>
    </Link>
    </ProductGroup>
)

export default ProductChip