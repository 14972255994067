import React from "react";
import styled from "styled-components";

const TileGroup = styled.div`
    width: 180px;
    height: 180px;
    background: white;
    border-radius: 20px;
    text-align: center;
    margin-bottom: 64px;

    @media (max-width: 400px) {
        transform: scale(0.9);
        margin-bottom: 0px;
    }
`

const Asset = styled.div`
    height: 119px;
    background: linear-gradient(180deg, #FFFFFF 0%, #F1F1F1 102.29%);
    border-radius: 100px 100px 500% 500% /100%;

    img {
        padding-top: 19px;
        height: 76px;
    }


`

const Title = styled.h3`
    margin: 4px 10px;
    font-size: 16px;
    font-weight: 900;
    padding: 5px;
    color: #1FA9EB;
}
`


const Tile = props => (
    <TileGroup>
        <Asset><img alt="#" src={props.image}/></Asset>
        <Title>{props.title}</Title>
    </TileGroup>
)

export default Tile